<template>
  <main>
    <v-container id="maincontainer" fluid fill-height>
      <v-row align="center" justify="center">
        <v-col class="px-15">
          <v-col cols="12">
            <v-img
              :src="require('../assets/logo.png')"
              class="my-1"
              contain
              height="75"
            />
          </v-col>
          <v-form ref="Login" class="text" v-model="loginValid" lazy-validation>
            <v-text-field 
              outlined 
              label="Username"
              prepend-icon="mdi-account-circle"
              v-model="username"
              required
              :rules="[(v) => !!v || 'Username is required']"
              />
            <v-text-field
              :type="showPassword ? 'text' : 'password'"
              outlined
              label="Password"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              v-model="password"
              required
              :rules="PasswordRules"
            />
            <p>{{ error }}</p>
          </v-form>

          <v-row class="my-4" align="center" justify="center">
            <v-btn dark color="oug" min-width="250" light depressed @click="validate" elevation="24">
              Login
            </v-btn>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <v-row class="text mt-4" align="center" justify="center">
            <router-link class="text mr-2" to="/Register">
              Register
            </router-link>
            /
            <router-link class="text ml-2" to="/Reset">
              Forgot Password
            </router-link>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>

export default {
  name: "Login",
  prop: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loginValid: true,
      showPassword: false,
      username: null,
      password: null,
      PasswordRules: [(value) => !!value || "Password is Required!"],
      contact_id: 0,
      association: 0,
      error: null,
    };
  },
  created() {},
  methods: {
    Login() {
      let credentials = { username: this.username, password: this.password };
      this.$store
        .dispatch("login", credentials)
        .then(() => {
          this.$router.push("/");
        })
        .catch((err) => {
          this.error = err.response.data.message;
        });
    },
    validate() {
      if (this.$refs.Login.validate()) {
        this.Login();
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.text {
  font-size: 18px;
  color: blue;
}

</style>
