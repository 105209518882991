<template>
  <v-container>
    <div>
      <v-switch
        v-model="know_username"
        :label="`I know my Username`"
      ></v-switch>
      <v-form ref="Reset" v-model="resetValid" lazy-validation>
        <v-text-field
          outlined
          v-if="know_username"
          class="mt-2"
          label="username"
          prepend-inner-icon="mdi-account-key"
          v-model="username"
          dense
          :rules="[rules.required]"
        />

        <v-text-field
          outlined
          v-if="!know_username"
          class="mt-2"
          label="Firstname"
          prepend-inner-icon="mdi-account-circle"
          v-model="firstname"
          dense
          :rules="[rules.required]"
        />

        <v-text-field
          outlined
          v-if="!know_username"
          class="mt-2"
          label="Surname"
          prepend-inner-icon="mdi-account-circle"
          v-model="surname"
          dense
          :rules="[rules.required]"
        />

        <v-text-field
          outlined
          label="Email"
          class="mt-2"
          prepend-inner-icon="mdi-email"
          v-model="email"
          dense
          :rules="[rules.required, rules.email]"
        />
        <v-btn
          class="mt-2"
          color="oug"
          dark
          depressed
          @click="validate"
          elevation="24"
        >
          <v-icon left>mdi-account-convert</v-icon>
          Reset
        </v-btn>
        <p></p>
        <router-link to="/Login">
          Already have an account
        </router-link>
      </v-form>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      resetValid: true,
      know_username: true,
      firstname: "",
      surname: "",
      email: "",
      username: "",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    reset() {
      this.$store
        .dispatch("reset", {
          firstname: this.firstname,
          surname: this.surname,
          email: this.email,
          username: this.username,
        })
        .then(() => {
          this.$toast.success(
            `We have resent your credentials and sent you an email with the new details!`,
            {
              timeout: 5000,
            }
          );
          this.$router.push("/Login");
        });
    },
    validate() {
      if (this.$refs.Reset.validate()) {
        console.log("validated");
        this.reset();
      }
    },
  },
};
</script>

<style scoped></style>
